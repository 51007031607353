<template>
  <div class="pricing">
     <div class="top-four-bx">
        <div class="container">
            <div class="innerbody-sec">
               <div class="inner-bx">
                    <div class="inner-wht-txt-bx inner-brown-bg">
                    <h2>Zorg voor je kroon</h2>
                    <p>Iedere behandelingen is gebasseerd op persoonlijke aandacht en advies. Iedere krul is uniek en ik leer jou tijdens de behandeling graag hoe je daar het maximale uit kunt halen.</p>
                    </div>
                </div>
                <div class="inner-bx">
                    <div class="inner-img-bx">
                        <VueSlickCarousel :arrows="false" :dots="true">
                        <div><img src="../assets/images/priceBanner1.png" alt=""></div>
                        <div><img src="../assets/images/priceBanner2.png" alt=""></div>
                        <div><img src="../assets/images/priceBanner3.png" alt=""></div>
                        <div><img src="../assets/images/priceBanner4.png" alt=""></div>
                      </VueSlickCarousel>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="container">
        <div class="priceListSection">
          <div class="priceTopHeading">
            <h2>Krullen behandelingen</h2>
          </div>
          <div class="priceHolder">
            <div class="priceBoxHolder">
              <div class="priceDetails">
                <h2>Premium Curls Cut</h2>
                <p>Krullen advies, wassen, verzorgen, knipppen en in model brengen met diffuser.</p>
              </div>
              <div class="priceList">€ 92,50</div>
            </div>
            <div class="priceBoxHolder">
              <div class="priceDetails">
                <h2>Premium Curls Cut &amp; Detox Treatment</h2>
                <p>Krullen advies, wassen, Detox behandeling, verzorgen, knipppen en in model brengen met diffuser.</p>
              </div>
              <div class="priceList">€ 117,50</div>
            </div>
            <div class="priceBoxHolder">
              <div class="priceDetails">
                <h2>Premium Curls Cut &amp; Diamond Herstelbehandeling (Olaplex)</h2>
                <p>Krullen advies, wassen, Herstelbehandeling, verzorgen, knipppen en in model brengen met diffuser.</p>
              </div>
              <div class="priceList">€ 122,50</div>
            </div>
            <div class="priceBoxHolder">
              <div class="priceDetails">
                <h2>Diamond Herstelbehandeling (Olaplex)</h2>
                <p>Krullen wassen, Herstelbehandelin, verzorgen en in model brengen met diffuser.</p>
              </div>
              <div class="priceList">€ 55,50</div>
            </div>
            <div class="priceBoxHolder">
              <div class="priceDetails">
                <h2>Wash &amp; Style</h2>
                <p>Krullen wassen, verzorgen en in model brengen met diffuser.</p>
              </div>
              <div class="priceList">€ 45,50</div>
            </div>
            <div class="priceBoxHolder">
              <div class="priceDetails">
                <h2>Premium Kids Curls</h2>
                <p>Krullen advies, wassen, verzorgen, knipppen en in model brengen met diffuser voor kinderen. Prijs in overleg.</p>
              </div>
              <div class="priceList"> - </div>
            </div>
          </div>
        </div>
        <div class="section4">
          <div class="relaxBg">
            <div class="row align-items-center">
              <div class="col-lg-6 order-lg-2 hover14">
                <figure><img src="../assets/images/relaxImage.png" alt=""></figure>
              </div>
              <div class="col-lg-6">
                <div class="sec4Cn">
                  <h2>Ontspannen</h2>
                  <p>Een unieke combinatie van persoonlijke aandacht, krullenadvies, ontspanning, een spa herstel- of detoxbehandeling en met een goede bos krullen de deur weer uit.</p>
                  <div class="certiSectionFt">
                    <div class="certiname">
                      <h3><a href="#">Bekijk de behandelingen</a></h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="before-footer-sec">
      <div class="container">
        
        <div class="section5">
            <div class="sec5Inner">
                <div class="appoText">Klaar om aan jouw krullen reis te beginnen?</div>
                <div class="appoBtn">
                    <a data-bs-toggle="modal" data-bs-target="#appoModal" class="btnAf">AFSPRAAK MAKEN</a>
                </div>
            </div>
        </div>
        <div class="section6">
            <div class="divider">
                <img src="../assets/images/divider.svg" alt="">
            </div>
            <div class="sectionHeading">
                <h2>Recensies</h2>
            </div>
            <div class="testiHolder">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="testiBox">
                            <div class="startrating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </div>
                            <h3>Mijn haar zit super!</h3>
                            <p>Heel blij dat ik bij Roos geweest ben, heerlijke spa behandeling en goeie tips gekregen!</p>
                            <h4>~Annemieke</h4>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="testiBox">
                            <div class="startrating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </div>
                            <h3> Een brede glimlach!</h3>
                            <p>Wat een super fijne sfeer in de salon. Helemaal blij met het resultaat. En ook een heerlijk moment voor jezelf.</p>
                            <h4>~ Natasja</h4>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="testiBox">
                            <div class="startrating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </div>
                            <h3>Roos weet echt wat ze doet!</h3>
                            <p>Na twee behandelingen is mijn haar al enorm beter geworden. Kan nu eindelijk mijn krullen laten zoals ze zijn, zonder pluis.</p>
                            <h4>~Bernadette</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="alltesti text-center">
                <h3><a href="#">Bekijk meer</a></h3>
            </div>
        </div>
      </div>
    </div>
    
  </div>
</template>


<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
 
  export default {
    name: 'Pricing',
    components: { VueSlickCarousel },
  }
</script>